<template>
    <Dialog style="width: 600px; height: 380px" v-model:visible="show" @hide="up" header="添加数据字典">
        <div class="card">
            <div class="p-grid p-col-12 p-field">
                <label class="p-col-2" style="font-weight: bold">字典标识：</label>
                <div class="p-col-10">
                    <InputText v-model="form.myKey" style="width: 100%" placeholder="标示,字符和数字"></InputText>
                </div>
            </div>
            <div class="p-grid p-col-12 p-field">
                <label class="p-col-2" style="font-weight: bold">字典名称：</label>
                <div class="p-col-10">
                    <InputText v-model="form.myCaption" style="width: 100%" placeholder="字典名称"></InputText>
                </div>
            </div>
            <div class="p-grid p-col-12 p-field">
                <label class="p-col-2" style="font-weight: bold">字典内容：</label>
                <div class="p-col-10">
                    <InputText v-model="form.myValue" style="width: 100%" placeholder="值"></InputText>
                </div>
            </div>
            <div class="p-grid p-jc-center p-mt-4">
                <Button @click="addDictionary" style="width: 20%" label="保存" class="p-mr-2"  icon="pi pi-fw pi-save"/>
            </div>
        </div>
        <Toast/>
    </Dialog>
</template>

<script>
    export default {
        name: "",
        data() {
            return {
                show: false,
                form: {
                    myKey: null,
                    myValue: null,
                }
            }
        },
        methods: {
            init(data) {
                this.show = true
                this.form = data
            },
            addDictionary() {
                this.$http.post('/dictionary/addDictionary',this.form).then((res) => {

                    if (res.status == 200 && res.data){
                        this.show = false;
                        this.$toast.add(
                            {severity: 'success', summary: '添加成功', life: 3000}
                        )

                    }else {
                        this.show = true;
                        this.$toast.add({severity:'warn', summary: '添加失败，请检查，全部字段不可重复且不可为空，或者稍后再试', life: 3000})
                    }
                })
            },
            up() {
                this.$emit("close","num")
            }
        }
    }
</script>

<style scoped>

</style>