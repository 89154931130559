<template>
    <div class="card ">

            <Breadcrumb :model="items"/>

        <div class="p-d-flex p-col">
            <Button  @click="addOrSave" icon="pi pi-fw pi-plus" label="添加" class="p-mr-2"/>
            <InputText v-model="myCaption" placeholder="名称搜索"/>
            <Button @click="dictionary"  icon="pi pi-search" label="查询" class="p-ml-2" />
        </div>
        <div class="p-col-12">
            <DataTable ref="dt" :value="from" v-model:selection="selectedProducts" dataKey="id"
                       :auto-layout="true"
                       :paginator="true" :rows="10"
                       paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                       :rowsPerPageOptions="[5,10,25]"
                       currentPageReportTemplate="显示 {first} 到 {last} 条记录，总计{totalRecords}记录">
                <Column field="id" header="序号" :sortable="true"></Column>
                <Column field="myKey" header="字典标示" :sortable="false"></Column>
                <Column field="myCaption" header="字典名称" :sortable="false"></Column>
                <Column field="myValue" header="字典值" :sortable="false"></Column>
                <Column :exportable="false" field="operation" header="操作" headerStyle="width:25%;">
                    <template #body="slotProps">
                        <Button @click="addOrSave(slotProps.data)" class="p-button-sm p-m-1">编辑</Button>
                        <Button class="p-button-danger p-button-sm p-m-1" @click="del(slotProps.data)">删除</Button>
                    </template>
                </Column>
            </DataTable>
            <Dialog v-model:visible="deleteProductDialog" :style="{width: '350px'}" header="确认" :modal="true">
                <div class="confirmation-content">
                    <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
                    <span v-if="product">你确认要删除 <b>{{ product.myKey }}</b>?</span>
                </div>
                <template #footer>
                    <Button label="否" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false"/>
                    <Button label="是" icon="pi pi-check" class="p-button-text" @click="deleteProduct(product.id)"/>
                </template>
            </Dialog>
        </div>
        <AddDictionary @close="hide" ref="add" v-if="add"></AddDictionary>
    </div>
    <Toast/>
</template>

<script>
    import AddDictionary from "../../platform/DataDictionary/AddDictionary"
    export default {
        name: "",
        components:{AddDictionary},
        data() {
            return {
                myCaption: null,
                deleteProductDialog: false,
                product: {},
                add: false,
                selectedProducts: [],
                items: [
                    {label: '系统设置'},
                    {label: '数据字典管理', to: '/dataDictionaries'}
                ],
                from: []
            }
        },
        mounted() {
            this.show()
        },
        methods: {
            dictionary() {
                this.$http.get('/dictionary/selectByTerm',{params:{'myCaption':this.myCaption}}).then((res) => {
                    this.from = res.data
                    //this.$toast.add({severity: 'success', summary: '成功', detail: '查询成功', life: 3000});
                })

            },
            addOrSave(data) {
                this.add = true;
                this.$nextTick(()=>{
                    this.$refs.add.init(data)
                })
            },
            show() {
                this.$http.get('/dictionary/selectAll').then((res) => {
                    this.from = res.data
                })
            },
            del(product) {
                this.product = product;
                this.deleteProductDialog = true;
            },
            deleteProduct(id) {
                this.$http.get('/dictionary/del', {params: {'id': id}}).then(() => {
                    this.deleteProductDialog = false;
                    this.$toast.add({severity: 'success', summary: '成功', detail: '删除成功', life: 3000});
                    this.show();
                })
            },
            hide() {
                this.show()
            }
        }
    }
</script>

<style scoped>

</style>